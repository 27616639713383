import './App.css';
import AccountInfo from './AccountInfo';

function App() {
  return (
    <AccountInfo />
  );
}

export default App;
